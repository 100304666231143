//settings
$fa-font-path: "../font";

// Colors //
$black: #000;
$white: #fff;
$pink: #CBC9C9;
$pink-l: #d7e2f6;
$primary-color: #3770CF;
$primary-color-l: #148aea;
$gray: #656565;

//Fonts
$primary-font: 'Inter', sans-serif;
$secondary-font: 'Bebas Neue', sans-serif;
