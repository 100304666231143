@font-face {
    font-family: 'Inter';
    src: url('../font/Inter-Regular.woff2') format('woff2'),
        url('../font/Inter-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Bebas Neue';
    src: url('../font/BebasNeue-Regular.woff2') format('woff2'),
        url('../font/BebasNeue-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

