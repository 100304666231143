@import "variables";
// includes
@import "includes/helpers";
@import "includes/normalize";
@import "includes/fonts";
@import "../../node_modules/magnific-popup/dist/magnific-popup";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid"; 

html {
    font-size: 16px;
    line-height: 1.2;
    overflow-x: hidden;
    @media screen and (max-width: 980px){
        font-size: 13px;
    }
    @media screen and (max-width: 820px){
        font-size: 11px;
    }
}

body {
    background-color: $primary-color;
    color: $black;
    font-family: $primary-font;
    font-weight: normal;
    font-size: rem(16);
    line-height: 1.2;
    overflow: hidden;
}

a{
    color: inherit;
    transition: .3s all;
    @include hover{
        color: $primary-color-l;
    }
}


.btn{
    font-size: rem(25);
    margin: rem(50) auto;
    letter-spacing: .1em;
    @media screen and (max-width: 820px){
        letter-spacing: .05em;
    }
    padding: rem(15) rem(40);
    border-radius: rem(30);
    background-color: $pink;
    color: $white;
    text-decoration: none;
    display: inline-block;
    @include hover{
        background-color: $pink-l;
    }
}

.social{
    margin: 0;
    padding: 0;
    color: $primary-color;
    font-size: rem(30);
    li{
         display: inline-block;
         vertical-align: middle;
         &+li{
            margin-left: .6em;
         }
    }
    a{
        @include hover{
            color: $primary-color-l;
        }
    }

    .btn{
        font-size: rem(14);
        text-transform: uppercase;
        letter-spacing: 0;
        padding: .8em rem(40);
        background-color: $primary-color;
        color: $pink;
        margin: 0;
        @include hover{
            color: $pink;
            background-color: $primary-color-l;
        }
    }
}

#header{
    background-image: url('../img/hero.png');
    background-size: 100% auto;
    background-position: bottom center;
    background-repeat: no-repeat;
    min-height: 80vw;
    padding: rem(30) 0;
    .container{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    @media screen and (max-width: 700px){
        min-height: 150vw;
        background-size: auto 100%;
        .social{
            display: flex;
            flex-direction: column;
            li{
                display: block;
                text-align: right;
                &+li{
                    margin: .5em 0 0;
                }
                order: 2;
                &:last-child{
                    order: 1;
                    margin-bottom: rem(30);
                }
            }
        }
        .container{
            align-items: flex-start;
        }
    }
    
    h1{
        margin: 0;
        padding: 0;
        line-height: 0;
        img{
            width: rem(380);
            
            @media screen and (max-width: 500px){
                width: rem(280);
            }
        }
    }
}

#main{
    color: $white;
    text-align: center;
    margin-top: -40vw;
    
    @media screen and (max-width: 700px){
        margin-top: -30vw;
    }
    p{
        font-size: rem(40);
        letter-spacing: .2em;
        @media screen and (max-width: 820px){
            letter-spacing: .05em;
        }
        margin: 0;
        span{
            display: inline-block;
        }
    }
    .title{
        letter-spacing: 0;
        font-family: $secondary-font;
        text-transform: uppercase;
        font-size: rem(90);
        margin: .7em auto;
        line-height: 1;
        
        @media screen and (max-width: 700px){
            font-size: rem(60);
        }
    }
    img+p{
        font-size: rem(28);
        margin: rem(50) auto;
        width: 100%;
        max-width: rem(720);
    }

    .media-responsive + p{
        font-size: rem(28);
        letter-spacing: .1em;
        @media screen and (max-width: 820px){
            letter-spacing: .05em;
        }
        margin: rem(30) auto 0;
        width: 100%;
        color: $primary-color;
    }

    #music{
        padding-bottom: rem(600);
        background-color: $pink;
        background-image: url('../img/texture-bg.jpg');
        background-size: cover;
        @media screen and (max-width: 820px){
            padding-bottom: 30vw;
        }
    }

    #video{
        padding: 1px 0 rem(70);

        .container{
            margin-top: rem(-600);
            @media screen and (max-width: 820px){
                margin-top: -30vw;
            }
        }

        .title{
            color: $primary-color;
            font-family: $secondary-font;
            text-transform: uppercase;
            margin: .7em auto;
            line-height: 1;
            font-size: rem(80); 
            letter-spacing: 0; 
            @media screen and (max-width: 700px){
                font-size: rem(50);
            }
        }

        img{
            display: block;
            margin: rem(-300) auto 0;
        }

        .btn{
            font-size: rem(18);
            text-transform: uppercase;
            letter-spacing: 0;
            padding: rem(20) rem(40);
            background-color: $pink;
            color: $primary-color;
            @include hover{
                background-color: $pink-l;
            }
        }
    }

    
}

#signup, #signup-popup{
    .title{
        color: $white;
        font-family: $secondary-font;
        text-transform: uppercase;
        margin: .7em auto;
        line-height: 1;
        font-size: rem(65); 
        letter-spacing: 0; 
        @media screen and (max-width: 700px){
            font-size: rem(50);
        }
    }

    .klaviyo-form-version-cid_1,.klaviyo-form-version-cid_2{
        padding: 0 !important;
        width: rem(900) !important;
        max-width: 100%;
        margin: rem(10) auto;
        text-align: center;
        input{
            padding-bottom: 0;
            background: none;
            border: none;
            border-radius: 0 !important;
            border-bottom: 1px solid $white !important;
            font-size: rem(22);
            color: $white;
            border-radius: 0;
            &::placeholder{
                color: $white;
                text-transform: lowercase;
            }
        }


        div[data-testid="form-row"]{
            
            flex-wrap: wrap;
            >div{
                padding: 0;
                margin: 0;
                width: rem(400);
                max-width: 100%;
                margin: 0 auto;
                text-align: center;
            }
        }

        .btn{
            transition: .3s all;
            font-size: rem(18);
            border: none;
            color: $primary-color;
            text-transform: uppercase;
            letter-spacing: 0;
            padding: rem(18) rem(80);
        }

        button{
            transition: .3s all;
            @include hover{
                background-color: $pink-l !important;
            }
        }
        @media screen and (max-width: 600px){
            div[data-testid="form-row"]{
                >div{
                    flex-basis: 100% !important;
                    padding: rem(6) 0 !important;
                }
            }
        }
    }
}

#signup{
    padding: 0 0 rem(90);
    .title{
        margin-bottom: 0;
    }
    p, input{
        filter: drop-shadow( 0 10px 10px #333A);
    }
    .container{
        max-width: rem(1300);
    }
}

#signup-popup{
    padding: rem(100) rem(40) rem(50);
    color: $white;
    background-color:$primary-color;
    width: 100%;
    max-width: rem(1160);
    margin: rem(30) auto;
    position: relative;
    text-align: center;
    .title{
        margin-top: 0;
    }
    .mfp-close{
        color: $white !important;
        opacity: 1;
        font-size: rem(55);
        right: rem(30);
        top: rem(30);
    }
    input{
        border-bottom: 1px solid $white;
    }
}

#footer{
    background-color: $pink;
    
    background-image: url('../img/texture-bg.jpg');
    background-size: cover;
    position: relative;
    padding: rem(100) 0;
    text-align: center;

    .social{
        text-align: center;
    }
    p{
        font-size: rem(18);
        margin-top: 2em;
        letter-spacing: .1em;
        
        @media screen and (max-width: 820px){
            letter-spacing: .05em;
        }
    }
    .copyright{
        font-size: rem(10);
        text-transform: uppercase;
        text-align: center;
        
    }
    .keepgoing{
        position: absolute;
        top: 0;
        width: rem(534);
        max-width: 100%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
}

.container{
    max-width: rem(1230);
}

